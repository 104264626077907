.container {
  padding: 20px
}

.fieldset {
  border: 3px solid #B4ACD3;
  border-radius: 15px;
  width: 600px;
  padding: 20px !important;
  box-shadow: inset -2px -2px 2px grey;
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
}

.legend {
  background-image: linear-gradient(to right, #A5ABD5, #54459C);
  border-radius: 20px;
  font-weight: 800 !important;
  font-size: 28px !important;
  /* padding: 3px 5px !important; */
  margin: -15px !important;
  width: 274px !important;
  font-family: Arial, Helvetica, sans-serif;
  text-shadow: 1.74px 0 0 #fff,
    -1.74px 0 0 #fff,
    0 1.74px 0 #fff,
    0 -1.74px 0 #fff,
    1.74px 1.74px #fff,
    -1.74px -1.74px 0 #fff,
    1.74px -1.74px 0 #fff,
    -1.74px 1.74px 0 #fff;
  box-shadow: inset 1px 2px 3px #B4B2D6, inset -1px -2px 3px #302853;
}

.legend_internet {
  color: #4D3B98;
  padding-left: 10px;
}
.legend_search {
  color: #8678B8;
}

.legend_pointer {
  position: absolute;
  color: white;
  left: -18px;
  top: 15px;
  text-shadow: none;
  transform: rotate(60deg);
  font-size: 50px !important;
  overflow: visible;
  text-shadow:  
  2px 0 0 #999B9E,
    -2px 0 0 #999B9E,
    0 2px 0 #999B9E,
    0 -2px 0 #999B9E,
    5px 0px #868484,
    -2px -2px 0 #999B9E,
    2px -2px 0 #999B9E,
    -2px 2px 0 #999B9E;
}